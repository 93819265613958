<template>
  <b-card>
    <b-form @submit.prevent>
      <validation-observer ref="newRequestForm">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="gauche"
          >
            <div class="custom-form-group-container">

              <div class="d-flex">
                <span>{{ $t('REQUEST.SOCIETE') }}</span>
              </div>
              <b-form-group>
                <b-form-input
                  v-model="activeSociete.societe"
                  disabled
                />
              </b-form-group>

              <div class="d-flex">
                <span>{{ $t('REQUEST.HOTLINE') }}</span>
                <b-badge
                  v-b-tooltip.hover.v-primary
                  class="ml-1 badge-round user-select-none"
                  pill
                  variant="primary"
                  :title="$t('REQUEST.TOOLTIP.RESUME')"
                >?
                </b-badge>
              </div>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('REQUEST.HOTLINE')"
                  rules="required|min:10"
                >
                  <b-form-input
                    v-model="hotline"
                    :placeholder="$t('REQUEST.PLACEHOLDER.HOTLINE')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="custom-form-group-container">
              <div class="d-flex">
                <span>{{ $t('REQUEST.POLE') }}</span>
                <b-badge
                  v-b-tooltip.hover.v-primary.html="reformatPoleToolTip"
                  class="ml-1 badge-round user-select-none"
                  pill
                  variant="primary"
                >?
                </b-badge>
              </div>
              <b-form-group>
                <v-select
                  v-model="pole"
                  :options="poles"
                  :clearable="false"
                />
              </b-form-group>
            </div>

            <div class="custom-form-group-container">
              <div class="d-flex">
                <span>{{ $t('REQUEST.URGENCE') }}</span>
                <b-badge
                  v-b-tooltip.hover.v-primary.html="reformatUrgenceToolTip"
                  class="ml-1 badge-round user-select-none"
                  pill
                  variant="primary"
                >?
                </b-badge>
              </div>
              <b-form-group>
                <v-select
                  v-model="urgence"
                  :options="urgences"
                  :clearable="false"
                />
              </b-form-group>
            </div>

            <vue-dropzone
              id="fileUpload"
              ref="dropzone"
              :options="dropOptions"
              :use-custom-slot="true"
              @vdropzone-file-added="afterComplete"
              @vdropzone-removed-file="removeFile"
              @vdropzone-max-files-exceeded="removeFileFromDropZone"
              @vdropzone-error="checkiFErrorDropzone"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  {{ $t('DRAG_DROP_PLACEHOLDER') }}
                </h3><br>
                <span>{{ $t('REQUEST.DRAG_DROP_AUTHORIZED_FILES') }}</span><br>
                <span>{{ $t('REQUEST.DRAG_DROP_AUTHORIZED_MAX_FILES') }}</span>
              </div>

            </vue-dropzone>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="custom-form-group-container">
              <div class="d-flex">
                <span>{{ $t('REQUEST.DETAIL') }}</span>
                <b-badge
                  v-b-tooltip.hover.v-primary
                  class="ml-1 badge-round user-select-none"
                  pill
                  variant="primary"
                  :title="$t('REQUEST.TOOLTIP.DETAIL')"
                >?
                </b-badge>
              </div>
              <b-form-group>
                <b-form-textarea
                  v-model="detail"
                  rows="16"
                />
                <small
                  v-if="errorsField.detail"
                  class="text-danger"
                >{{ $t(errorsField.detail) }}</small>
              </b-form-group>
            </div>

            <b-button
              v-if="!isErrorDropzone"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="ml-1 btn-right"
              @click="submitRequest"
            >
              {{ $t('REQUEST.BUTTON_SUBMIT') }}
            </b-button>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BButton, VBTooltip, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import vueDropzone from 'vue2-dropzone'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    VBTooltip,
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    vueDropzone,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      displayAlert: false,
      required,
      loading: false,
      hotline: '',
      detail: '',
      pole: { label: this.$i18n.t('REQUEST.POLES.system'), value: 'system' },
      urgence: { label: this.$i18n.t('REQUEST.URGENCES.DETAIL'), value: 'detail' },
      urgences: [
        { label: this.$i18n.t('REQUEST.URGENCES.DETAIL'), value: 'detail' },
        { label: this.$i18n.t('REQUEST.URGENCES.GENANT'), value: 'genant' },
        { label: this.$i18n.t('REQUEST.URGENCES.BLOQUANT'), value: 'bloquant' },
      ],
      poles: [
        { label: this.$i18n.t('REQUEST.POLES.dev'), value: 'dev' },
        { label: this.$i18n.t('REQUEST.POLES.system'), value: 'system' },
        { label: this.$i18n.t('REQUEST.POLES.telecom'), value: 'telecom' },
      ],
      formFiles: [],
      files: [],
      dropOptions: {
        autoProcessQueue: false,
        url: 'http://localhost/',
        maxFilesize: 2,
        maxFiles: 4,
        chunking: true,
        chunkSize: 500,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        dictRemoveFile: 'Retirer',
        acceptedFiles: 'image/*,.xlsx,.xls,.pdf,.doc,.docx, .zip',
      },
      isErrorDropzone: false,
      errorsField: {
        detail: null,
      },
    }
  },
  computed: {
    activeSociete() {
      return this.$store.getters['societe/getActiveSociete']
    },
  },
  mounted() {
    if (!localStorage.activeSociete) {
      this.$swal({
        title: `${this.$i18n.t('APP.ERROR_MODAL_NO_SOCITE_TITRE')}`,
        html: `${this.$i18n.t('APP.ERROR_MODAL_NO_SOCITE_TEXT')}`,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(isConfirm => {
          if (isConfirm) {
            this.$store.dispatch('jwt/logout').then(() => {
              this.$router.push({ name: 'auth-login' })
            })
          }
        })
    }
  },
  methods: {
    reformatPoleToolTip() {
      return `<strong>${this.$i18n.t('REQUEST.TOOLTIP.POLE_1[0]')}</strong> : ${this.$i18n.t('REQUEST.TOOLTIP.POLE_1[1]')}
      </br>
      <strong>${this.$i18n.t('REQUEST.TOOLTIP.POLE_2[0]')}</strong> : ${this.$i18n.t('REQUEST.TOOLTIP.POLE_2[1]')}
      `
    },
    reformatUrgenceToolTip() {
      return `<strong>${this.$i18n.t('REQUEST.TOOLTIP.URGENCE_1[0]')}</strong> : ${this.$i18n.t('REQUEST.TOOLTIP.URGENCE_1[1]')}
      </br>
      </br>
      <strong>${this.$i18n.t('REQUEST.TOOLTIP.URGENCE_2[0]')}</strong> : ${this.$i18n.t('REQUEST.TOOLTIP.URGENCE_2[1]')}
      </br>
      </br>
      <strong>${this.$i18n.t('REQUEST.TOOLTIP.URGENCE_3[0]')}</strong> : ${this.$i18n.t('REQUEST.TOOLTIP.URGENCE_3[1]')}
      `
    },
    /**
     * Methode qui est appelé lors du submit du formulaire
     * Permet de faire les controlles des champs, si tout est OK, envoi le formulaire et affiche la modal de succes
     * @author Morgan Fleurquin <mfleurquin@absystech.fr>
     */
    submitRequest() {
      const that = this
      this.loading = true

      this.$refs.newRequestForm.validate().then(success => {
        if (success) {
          if (this.detail === '' || this.detail.length < 10) {
            that.errorsField.detail = `${this.$i18n.t('ERREURS.DETAIL_REQUIRED_1')} 10 ${this.$i18n.t('ERREURS.DETAIL_REQUIRED_2')}`
            that.loading = false
          } else {
            const form = new FormData()

            if (that.formFiles.length) {
              for (let i = 0; i < that.formFiles.length; i += 1) {
                form.append(`files[${i}]`, that.formFiles[i])
              }
            }

            form.append('hotline', that.hotline)
            form.append('detail', that.detail)
            form.append('pole', that.pole.value)
            form.append('urgence', that.urgence.value)
            form.append('societe', that.activeSociete.idSociete)

            that.$store.dispatch('hotline/insert', form)
              .then(r => {
                that.loading = false
                that.success(r.data)
              })
              .catch(() => {
                that.$swal(
                  {
                    title: `${this.$i18n.t('REQUEST.ERROR_MODAL_TITRE')}`,
                    html: `${this.$i18n.t('REQUEST.ERROR_MODAL_TEXT')}`,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  },
                )
                that.errorShow = true
                that.loading = false
              })
          }
        } else {
          that.loading = false
        }
      })
    },
    /**
     * Methode qui d'afficher la modal de succes de création d'une demande
     * Update les notifications dans la nabbar
     * Reinitialise le formulaire
     * Renvoi vers la pages des requetes qu user
     * @author Morgan Fleurquin <mfleurquin@absystech.fr>
     */
    success(data) {
      // On met à jour les notifs dans la navbar
      this.$store.dispatch('hotline/getNotificationsHotline', { idSociete: this.activeSociete.idSociete })
      // On affiche la popup de succes
      this.$swal(
        {
          title: `${this.$i18n.t('REQUEST.SUCCESS_MODAL_TITRE_1')}${data} ${this.$i18n.t('REQUEST.SUCCESS_MODAL_TITRE_2')}`,
          html: `${this.$i18n.t('REQUEST.SUCCESS_MODAL_TEXT')}`,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        },
      ).then(isConfirm => {
        if (isConfirm) {
          // On reinitialise le formulaire
          this.hotline = ''
          this.detail = ''
          this.pole = { label: this.$i18n.t('REQUEST.POLES.SYSTEM'), value: 'system' }
          this.urgence = { label: this.$i18n.t('REQUEST.URGENCES.DETAIL'), value: 'detail' }
          this.formFiles = []
          this.files = []

          this.$router.push({ name: 'requests-personal' })
        }
      })
    },
    /**
     * Surcharge du after complete de la dropzone
     * @author Morgan Fleurquin <mfleurquin@absystech.fr>
     */
    afterComplete(file) {
      this.formFiles.push(file)
      return true
    },
    checkiFErrorDropzone(file) {
      if (file) {
        this.isErrorDropzone = true
      } else if (this.$refs.dropzone && this.$refs.dropzone.getRejectedFiles().length > 0) {
        this.isErrorDropzone = true
      } else {
        this.isErrorDropzone = false
      }
    },
    /**
     * Surcharge du remove file de la dropzone
     * @author Morgan Fleurquin <mfleurquin@absystech.fr>
     */
    removeFile(file) {
      this.formFiles = this.formFiles.filter(f => f !== file)
      this.checkiFErrorDropzone()
    },
    /**
     * Méthode permettant de supprimer le dernier fichier ajouté
     * si la limite de de fichiers acceptés est dépassée
     * @author Francisco Fernandez
     */
    removeFileFromDropZone(file) {
      this.$refs.dropzone.removeFile(file)
      this.checkiFErrorDropzone()
    },
  },
}
</script>

<style>
.custom-form-group-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.tooltip-inner {
  max-width: 500px !important;
  text-align: left !important;
}
.gauche {
  padding-right: 15px;
  border-right: 1px solid #ebe9f1;
}
.btn-right{
  float:right;
}
#fileUpload{
  margin-bottom: 10px;
  background: none;;
}

</style>
